import React, { useEffect, useState } from 'react'
import {NavLink} from 'react-router-dom'
import * as FcIcons from 'react-icons/fc';
import * as MdIcons from 'react-icons/md';

const ProductList = () => {
  const [product,setProduct] = useState([])
  const [disabled,setDisabled] = useState(false)
  const [delay,setDelay] = useState(true)
  
  const [confirm,setConfirm] = useState(false)

  const [title,setTitle] = useState('')
  const [stock,setStock] = useState('')
  const [cost,setCost] = useState('')  
  const [wholesale,setWholesale] = useState('') 
  const [offer,setOffer] = useState('')  
  const [retail,setRetail] = useState('')  
  const [quantity,setQuantity] = useState('')  
  const [category,setCategory] = useState('') 
  const [description,setDescription] = useState('')
  
  const [totalCost,setTotalCost] = useState('')

  useEffect(()=>{
    setTimeout(() => setDelay(false), 9999)
    getProduct()
    // eslint-disable-next-line 
  },[])
  
  // Get Data
  const getProduct = async () => {
    let result = await fetch('https://api.zakhira.store/products')
    result = await result.json()
    if(result){    
      let  inStock = result.filter((i)=>(i.stock===true))
      let  outStock = result.filter((i)=>(i.stock===false))

      let a = result.map((i) => (Number(i.cost) * Number(i.quantity)))
      let totalSum = a.reduce((accumulator, currentValue) => accumulator + currentValue, 0);

      setTotalCost(totalSum);
      setProduct([...outStock, ...inStock])
    }
  }

  // Delete Data
  const remove = async (id) => {
    const comfirmBox = window.confirm("Are You Sure?")
    if(comfirmBox === true){
      let result = await fetch(`https://api.zakhira.store/deleteProduct/${id}`,{
        method:'delete'
      })
      result = await result.json()
      if(result){
        getProduct()
      }
    }
  }
  
  // Search Data
  const search = async(e) => {
    const key = e.target.value
    if(key){
      let result = await fetch(`https://api.zakhira.store/searchProduct/${key}`)
      result = await result.json()
      if(result){
        setProduct(result)
      }
    }
    else{
      getProduct()
    }
  }

  const checkbox= async(id ,e) => {
    let stock = e.target.checked
    try{
      let result = await fetch(`https://api.zakhira.store/updateStock/${id}`,{
        method:'put',
        body:JSON.stringify({ stock }),
        headers:{'Content-Type':'application/json'}
      })
      result = await result.json()
      
      if(result.message){
        // alert(result.message)
        getProduct()
      }
      else{ alert("Error") }
    }
    catch{ alert("Error") }
  }

  const clone = async(i) => {
    setTitle(i.title); setStock(i.stock); setCost(i.cost); setWholesale(i.wholesale); setOffer(i.offer); setRetail(i.retail);
    setQuantity(i.quantity); setCategory(i.category); setDescription(i.description); setConfirm(true) 
  }

  // Clone
  const submit = async(i) => {
      
      try{
        setDisabled(true)
        
        let result = await fetch('http://localhost:5000/cloneProduct',{
          method:'post',
          body:JSON.stringify({ title, stock, cost, wholesale, offer, retail, quantity, category, description }),
          headers:{'Content-Type':'application/json'}
        })
        result = await result.json()
        
        if(result.message){
          setDisabled(false)
          alert(result.message)
          getProduct()
          setConfirm(false)
          
        } 
      }   
      catch{
        setDisabled(false)
        alert("Error")
        setConfirm(false)
      }
    }

    
    return (
    <div className='container-fluid mt-4'>
      <div className='row'>
        <div className='col-sm-4'></div>
        <h2 className='text-primary col-sm-4 mt-4'>Products</h2>
        <div className='col-sm-4'>
          {confirm?
          <><button className={`btn btn-primary mt-4 ${disabled ? 'disabled' : null}`} onClick={submit}>Confirm</button>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <button className={`btn btn-danger mt-4 ${disabled ? 'disabled' : null}`} onClick={()=>setConfirm(false)}>Cancel</button></>:
          <input type="text" className="form-control mt-4" placeholder="Search....." onChange={search} />}
        </div>
      </div>
      


      <div className='table-responsive mt-4'>
        <table className="table text-light">
          <thead>
            <tr>
              <th className='col-1'>S&nbsp;no.</th>
              <th className='col-1'>Image</th>
              <th className='col-2'>Title</th>
              <th className='col-1'>Stock</th>
              <th className='col-1'>Cost&nbsp;Price</th>
              <th className='col-1'>Wholesale</th>
              <th className='col-1'>Offer&nbsp;Price</th>
              <th className='col-1'>Retail</th>
              <th className='col-1'>Quantity</th>
              <th className='col-1'>Total&nbsp;Cost</th>
              <th className='col-1'>Category</th>
              {/* <th className='col-3'>Description</th> */}
              <th className='col-1'>Clone</th>
              <th className='col-1'>Edit</th>
              <th className='col-1'>Delete</th>
            </tr>
          </thead>
          <tbody>
          
          {
              product.length>0 &&
              <tr className='bg-danger'>
                <td colSpan={9}></td>
                <td>₹{totalCost}/-</td>
                <td colSpan={4}></td>
              </tr>
            }

            {
              product.length>0?
              product.slice(0).reverse().map((i,index)=>(
                <tr key={i._id}>
                  <td className='pt-4'>{product.length-index}</td>
                  <td className='pt-4 pt-lg-1'><img loading='lazy' src={`https://api.zakhira.store/${(i.images && i.images[0])}`} className='col-10 rounded' height="70" alt='' /></td>
                  <td className='pt-4'>{i.title}</td>
                  
                  {i.stock?
                  <td className='pt-4'><input className="form-check-input" type="checkbox" checked onChange={(e)=>{checkbox(i._id, e)}} /></td>
                  :<td className='pt-4'><input className="form-check-input" type="checkbox" onChange={(e)=>{checkbox(i._id, e)}} /></td>}
                  <td className='pt-4'>₹{i.cost}/-</td>
                  <td className='pt-4'>₹{i.wholesale}/-</td>
                  <td className='pt-4'>₹{i.offer}/-</td>
                  <td className='pt-4'>₹{i.retail}/-</td>
                  <td className='pt-4'>{i.quantity} pcs</td>
                  <td className='pt-4'>₹{i.cost*i.quantity}/-</td>
                  <td className='pt-4'>{i.category}</td>
                  
                  {/* {i.description.split('- ')[0]?
                  <td className='pt-4' style={{textAlign: 'start'}}>{i.description.split('- ')[0]}...</td>:
                  <td className='pt-4' style={{textAlign: 'start'}}><li>{i.description.split('- ')[1]}</li>...</td>
                  } */}
                  
                  <td className='pt-3 pt-md-2'><button className='btn border-dark' onClick={()=>{clone(i)}}><h2 className='text-success'><MdIcons.MdContentCopy/></h2></button></td>
                  <td className='pt-3 pt-md-2'><NavLink className='btn border-dark' to={'/updateProduct/'+i._id}><h2><FcIcons.FcEditImage/></h2></NavLink></td>
                  <td className='pt-3 pt-md-2'><button className='btn border-dark' onClick={()=>{remove(i._id)}}><h2 className='text-danger'><MdIcons.MdDeleteForever/></h2></button></td>
                </tr>
              )):
              delay?
                <tr>
                  <td colSpan={14}><h4>Loading <div className="spinner-border" role="status"></div></h4></td>
                </tr>:
                <tr>
                  <td colSpan={14}><h4 className='text-danger'>No Product Found</h4></td>
                </tr>
            }
            
          
          </tbody>
        </table>
      </div>

    </div>
  )
}

export default ProductList